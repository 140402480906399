.loading {
  animation: fingerChange 1.5s linear infinite;
}
.music {
  animation: fingerChange 3s linear infinite;
}
.unMusic {
  animation: fingerChange 3s linear infinite;
  animation-play-state: paused;
}
@keyframes fingerChange {
  0% {
    transform: rotate(0deg) ;
  }
  50% {
    transform: rotate(180deg) ;
  }
  100% {
    transform: rotate(360deg) ;
  }
}